import '@styles/widgets/tabbed-banner.scss';

jQuery(function () {
   const TabbedBanner = {};
   TabbedBanner.isFirstClick = true;

   TabbedBanner.init = ($widget) => {
      const $tabs = jQuery($widget.find('.ssea-tabbed-banner-tab'));
      if ($tabs.length > 0) {
         $tabs.each(function (index, tab) {
            jQuery(tab).on('click', function () {
               $tabs.removeClass('ssea-tabbed-banner-tab--active');
               jQuery(this).addClass('ssea-tabbed-banner-tab--active');
               if (TabbedBanner.isFirstClick) {
                  jQuery('.ssea-tabbed-banner__panel-subtitle').slideUp();
                  jQuery('.ssea-tabbed-banner__panel-middle').addClass('ssea-tabbed-banner__panel-middle--active');
                  jQuery('.ssea-tabbed-banner__panel-bottom').addClass('ssea-tabbed-banner__panel-bottom--active');
                  TabbedBanner.isFirstClick = false;
               }
               TabbedBanner.selectProductFeatures(jQuery(this).data('target-id'));
               TabbedBanner.selectProductMeta(jQuery(this).data('target-id'));
               TabbedBanner.selectProduct(jQuery(this).data('target-id'));
            });
         });
         // Disable intro animation for now. Ticket CBCSM-101.
         // eslint-disable-next-line
        //  TabbedBanner.executeIntroAnimation();
      }
   };

   TabbedBanner.executeIntroAnimation = () => {
      jQuery('.ssea-tabbed-banner__column--right').css({ visibility: 'visible' });
      jQuery('.ssea-product__title').css({ opacity: 1 });
      setTimeout(() => {
         jQuery('.ssea-product__subtitle').css({ opacity: 1 });
      }, 600);
      setTimeout(() => {
         jQuery('.ssea-product__picture').css({ opacity: 1 });
      }, 1200);
      setTimeout(() => {
         jQuery('.ssea-tabbed-banner__left-panel').css({ top: 0 });
      }, 1800);
   };

   TabbedBanner.selectProduct = (id) => {
      const $targetProduct = jQuery(jQuery(`div[data-product-item="${id}"]`));
      jQuery('.ssea-product--active').fadeOut();
      $targetProduct.fadeIn();
      $targetProduct.addClass('ssea-product--active');
   };

   TabbedBanner.selectProductMeta = (id) => {
      const $targetMeta = jQuery(jQuery(`div[data-product-meta="${id}"]`));
      jQuery('.ssea-product-meta--active').fadeOut();
      $targetMeta.fadeIn();
      $targetMeta.addClass('ssea-product-meta--active');
   };

   TabbedBanner.selectProductFeatures = (id) => {
      const $targetFeatures = jQuery(jQuery(`div[data-product-features="${id}"]`));
      jQuery('.ssea-product-features--active').fadeOut();
      $targetFeatures.fadeIn();
      $targetFeatures.addClass('ssea-product-features--active');
   };

   jQuery(window).on('elementor/frontend/init', () => {
      elementorFrontend.hooks.addAction('frontend/element_ready/ssea-tabbed-banner.default', TabbedBanner.init);
   });
});
